// Navbar.js

import React, { useState, useEffect } from 'react';
import '../Css/Navbar.css';
import Translate from './Translate';

const LanguageContext = React.createContext();

const Navbar = () => {
  const [scrolled, setScrolled] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const [showLanguages, setShowLanguages] = useState(false);
  const [currentLanguage, setCurrentLanguage] = useState('en'); // Default language is English

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleMenuClick = () => {
    setMenuOpen(!menuOpen);
  };

  const handleLanguagesHover = () => {
    setShowLanguages(true);
  };

  const handleLanguagesLeave = () => {
    setShowLanguages(false);
  };

  const handleLanguageChange = (language) => {
    setCurrentLanguage(language);
  };
  
  return (
    // <LanguageContext.Provider value={{ currentLanguage, setCurrentLanguage }}>
    //  <Translate>
    <div className={`navbar ${scrolled ? 'scrolled' : ''}`}>
      <div className="logo">
        <img src="logo.png" alt="Logo" />
      </div>

       {/* Translation component */}
       {/* <Translate targetLanguage={currentLanguage} /> */}

      {/* Mobile menu buttaon */}
      <div className={`menu-button ${menuOpen ? 'open' : ''}`} onClick={handleMenuClick}>
        <div className="bar"></div>
        <div className="bar"></div>
        <div className="bar"></div>
      </div>

      {/* Navigation links for larger screens */}
      <div className={`nav-links ${menuOpen ? 'open' : ''}`}>
        <div className='home'>
          <a href="#" className="a">Home</a>
          <div className='home-dropdown'>
            <span className='dropdown-span'>Home</span>
            <ul className='home-dropdown-modal'>
              <li className='home-dropdown-items'><a href='#'>Information 1</a></li>
              <li className='home-dropdown-items'><a href='#'>Information 2</a></li>
              <li className='home-dropdown-items'><a href='#'>Information 3</a></li>
              <li className='home-dropdown-items'><a href='#'>Information 4</a></li>
              <li className='home-dropdown-items'><a href='#'>Information 5</a></li>
              <li className='home-dropdown-items'><a href='#'>Information 6</a></li>
            </ul>
          </div>
        </div>
        <div className='about'>
        <a href="#" className="a">About</a>
        <div className='about-dropdown'>
        <span className='dropdown-span'>About</span>
            <ul className='about-dropdown-modal'>
              <li className='about-dropdown-items'><a href='#'>Information 1</a></li>
              <li className='about-dropdown-items'><a href='#'>Information 2</a></li>
              <li className='about-dropdown-items'><a href='#'>Information 3</a></li>
              <li className='about-dropdown-items'><a href='#'>Information 4</a></li>
              <li className='about-dropdown-items'><a href='#'>Information 5</a></li>
              <li className='about-dropdown-items'><a href='#'>Information 6</a></li>
            </ul>
        </div>
        </div>

        <div className='contact'>
        <a href="#" className="a">Contact</a>
        <div className='contact-dropdown'>
        <span className='dropdown-span'>Contact</span>
            <ul className='contact-dropdown-modal'>
              <li className='contact-dropdown-items'><a href='#'>Information 1</a></li>
              <li className='contact-dropdown-items'><a href='#'>Information 2</a></li>
              <li className='contact-dropdown-items'><a href='#'>Information 3</a></li>
              <li className='contact-dropdown-items'><a href='#'>Information 4</a></li>
              <li className='contact-dropdown-items'><a href='#'>Information 5</a></li>
              <li className='contact-dropdown-items'><a href='#'>Information 6</a></li>
            </ul>
        </div>
        </div>
        
        
        <div className='featured'>
        <a href="#" className="a">Features</a>
        <div className='featured-dropdown'>
        <span className='dropdown-span'>Features</span>
            <ul className='featured-dropdown-modal'>
              <li className='featured-dropdown-items'><a href='#'>Information 1</a></li>
              <li className='featured-dropdown-items'><a href='#'>Information 2</a></li>
              <li className='featured-dropdown-items'><a href='#'>Information 3</a></li>
              <li className='featured-dropdown-items'><a href='#'>Information 4</a></li>
              <li className='featured-dropdown-items'><a href='#'>Information 5</a></li>
              <li className='featured-dropdown-items'><a href='#'>Information 6</a></li>
            </ul>
        </div>
        </div>

        <div className='solutions'>
        <a href="#" className="a">Solutions</a>
        <div className='solutions-dropdown'>
        <span className='dropdown-span'>Solutions</span>
            <ul className='solutions-dropdown-modal'>
              <li className='solutions-dropdown-items'><a href='#'>Information 1</a></li>
              <li className='solutions-dropdown-items'><a href='#'>Information 2</a></li>
              <li className='solutions-dropdown-items'><a href='#'>Information 3</a></li>
              <li className='solutions-dropdown-items'><a href='#'>Information 4</a></li>
              <li className='solutions-dropdown-items'><a href='#'>Information 5</a></li>
              <li className='solutions-dropdown-items'><a href='#'>Information 6</a></li>
            </ul>
        </div>
        </div>

        <div className='resources'>
        <a href="#" className="a">Resources</a>
        <div className='resources-dropdown'>
        <span className='dropdown-span'>Resources</span>
            <ul className='resources-dropdown-modal'>
              <li className='resources-dropdown-items'><a href='#'>Information 1</a></li>
              <li className='resources-dropdown-items'><a href='#'>Information 2</a></li>
              <li className='resources-dropdown-items'><a href='#'>Information 3</a></li>
              <li className='resources-dropdown-items'><a href='#'>Information 4</a></li>
              <li className='resources-dropdown-items'><a href='#'>Information 5</a></li>
              <li className='resources-dropdown-items'><a href='#'>Information 6</a></li>
            </ul>
        </div>
        </div>
        
        <div className='partners'>
        <a href="#" className="a">Partners</a>
        <div className='partners-dropdown'>
        <span className='dropdown-span'>Partners</span>
            <ul className='partners-dropdown-modal'>
              <li className='partners-dropdown-items'><a href='#'>Information 1</a></li>
              <li className='partners-dropdown-items'><a href='#'>Information 2</a></li>
              <li className='partners-dropdown-items'><a href='#'>Information 3</a></li>
              <li className='partners-dropdown-items'><a href='#'>Information 4</a></li>
              <li className='partners-dropdown-items'><a href='#'>Information 5</a></li>
              <li className='partners-dropdown-items'><a href='#'>Information 6</a></li>
            </ul>
        </div>
        </div>

        <div className='support'>
        <a href="#" className="a">Support</a>
        <div className='support-dropdown'>
        <span className='dropdown-span'>Support</span>
            <ul className='support-dropdown-modal'>
              <li className='support-dropdown-items'><a href='#'>Information 1</a></li>
              <li className='support-dropdown-items'><a href='#'>Information 2</a></li>
              <li className='support-dropdown-items'><a href='#'>Information 3</a></li>
              <li className='support-dropdown-items'><a href='#'>Information 4</a></li>
              <li className='support-dropdown-items'><a href='#'>Information 5</a></li>
              <li className='support-dropdown-items'><a href='#'>Information 6</a></li>
            </ul>
        </div>
        </div>
      </div>
      

      {/* Language and Login for larger screens */}
      <div className={`language-login ${menuOpen ? 'open' : ''}`}>
        <span className="en" onClick={() => handleLanguageChange('en')}>EN</span>
        {/* <span className="hi" onClick={() => handleLanguageChange('en')}>EN</span> */}
        
        <ul className="dropdown-modal">
          <li className="dropdown-modal-items"><a href="">Hindi</a></li>
          <li className="dropdown-modal-items"><a href="">English</a></li>
        </ul>
        <a href="#" className="alogin">Login</a>
      </div>
    </div>
    //  </Translate>
    // </LanguageContext.Provider>
  );
};

export default Navbar;
