import React from 'react';
import '../Css/Feature.css';

const Feature = () => {
  // Data for the feature cards
  const featureData = [
    { id: 1, icon: 'f1.png', name: 'Internet Banking' },
    { id: 2, icon: 'f2.png', name: 'Quick Money Transfer' },
    { id: 3, icon: 'f3.png', name: 'Digital KYC System' },
    { id: 4, icon: 'f4.png', name: 'Fastag' },
    { id: 5, icon: 'f5.png', name: 'Debit Card Features' },
    { id: 6, icon: 'f6.png', name: 'Fingerprint Based Login' },
    { id: 7, icon: 'f7.png', name: 'Recharge & Bill Payments' },
    { id: 8, icon: 'f8.png', name: 'Customer Mobile App' },
    { id: 9, icon: 'f9.png', name: 'Agent Mobile App' },
    { id: 10, icon: 'f10.png', name: 'eNACH (Auto-Debit)' },
    { id: 11, icon: 'f11.png', name: 'Cibil/Credit Score' },
    { id: 12, icon: 'f12.png', name: 'Website Deliver' },
    { id: 13, icon: 'f13.png', name: 'Dedicated Support' },
    
    
    // Add more features as needed
  ];

  return (
    <div className='feature-container'>
      <h1 style={{fontSize:'2em',borderRadius:'5px', backgroundColor:'#0202a7',color:'white', width:'100%'}} className='mb-5'>NIDHI SOFTWARE Features</h1>
      <div className='feature-cards-container'>
        {featureData.map((feature) => (
          <div key={feature.id} className='feature-card'>
            <img src={feature.icon} alt={`Icon for ${feature.name}`}  />
            <p style={{fontSize:'1 em'}}>{feature.name}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Feature;
