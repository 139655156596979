import React, { useEffect } from 'react';
import '../Css/Header.css';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import SliderComponent from './SliderComponent';
import ThreeAnimated from './ThreeAnimated';
import WavyAnimation from './WavyAnimation';

gsap.registerPlugin(ScrollTrigger);

const Header = () => {
  useEffect(() => {
    const handleHeaderLoader = () => {
      // GSAP animations for the header section
      gsap.from('.header-section', { opacity: 0, duration: 1, delay: 0.5 });

      // Animation for waves
      gsap.to('.wave1', {
        x: '5%', // Move to the right by the container width
        duration: 5, // Adjust the duration as needed
        repeat: -1, // Repeat indefinitely
        ease: 'power1.inOut', // Adjust the easing function as needed
        scrollTrigger: {
          trigger: '.header-section',
          start: 'top center', // Adjust as needed
        },
      });

      gsap.to('.wave2', {
        x: '5%', // Move to the right by the container width
        duration: 5, // Adjust the duration as needed
        repeat: -1, // Repeat indefinitely
        ease: 'power1.inOut', // Adjust the easing function as needed
        scrollTrigger: {
          trigger: '.header-section',
          start: 'top center', // Adjust as needed
        },
      });

      gsap.to('.wave3', {
        x: '5%', // Move to the right by the container width
        duration: 5, // Adjust the duration as needed
        repeat: -1, // Repeat indefinitely
        ease: 'power1.inOut', // Adjust the easing function as needed
        scrollTrigger: {
          trigger: '.header-section',
          start: 'top center', // Adjust as needed
        },
      });
    };

    // Add event listener for the load event
    window.addEventListener('load', handleHeaderLoader);

    // Cleanup function to remove the event listener
    return () => {
      // Remove event listener when the component unmounts
      window.removeEventListener('load', handleHeaderLoader);
    };
  }, []); // Empty dependency array to run the effect only once

  return (
    <div className='header-section'>
      <img src="/topani1.svg" alt="Example Image" className="topani1" />
      {/* Image in the middle */}
      <div className="centered-image" >
    <div className="black-box">
    {/* <h1>NIDHI SOFTWARE PRO.</h1> */}

    <SliderComponent classvalue="nidhiimg" fade_s={true} img1="/nidhis.jpg" img2="/1_0473.jpg" img3='/4_0473.jpg' img4='/3_0473.jpg' img5='/2_0473.jpg' img6='/5_0473.jpg' widths='800px' heights='auto' speed_ns={5000} />
  </div>
</div>
      <img src="/topani2.svg" alt="Example Image" className="topani2" />
      {/* Waves at the bottom */}
      <WavyAnimation/>
      
    </div>
  );
};

export default Header;
