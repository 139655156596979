// App.js

import React, { useState } from 'react';
import './Css/App.css'; // Import your CSS file for App styling
import Final from './Final';
import Tlist from './Components/Tlist';
import ColumnsWithIcons from './Components/ColumnsWithIcons';
import Navbar from './Components/Navbar';
import TwoColumnLayout from './Components/TwoColumnLayout';
import Accordion from './Components/Accordion';
import WhiteColumn from './Components/WhiteColumn';
import Layout from './Components/Layout';
import Steps from './Components/Steps';
import QAndA from './Components/QAndA';
import Fast from './Components/Fast';
import Bslide from './Components/Bslide';
import Footer from './Components/Footer';
import RoundButton from './Components/RoundButton';
import ScrollButton from './Components/ScrollButton';
import PlanYourTrip from './Components/PlanYourTrip';
import Top from './Components/Top';
import Header from './Components/Header';
import Pricing from './Components/Pricing';
import MovingTextComponent from './Components/MovingTextComponent';
import Platform from './Components/Platform';
import LatestNewsCards from './Components/LatestNewsCards';
import LineDot from './Components/LineDot';
import BendingLine from './Components/BendingLine';
import CurlyLinesWithMovingDot from './Components/CurlyLinesWithMovingDot';
import AnimatedSVG from './Components/AnimatedSVG';
import Feature from './Components/Feature';
import Phone from './Components/Phone';
import GlowingButton from './Components/GlowingButton';
import TppWidget from './Components/TppWidget';
import Faqs from './Components/Faqs';
import Second from './Components/Second';
import Translate from './Components/Translate';
import LanguageContext from './Components/LanguageContext';
import ShineCard from './Components/ShineCard';
import WaterButton from './Components/WaterButton';
import LiquidButton from './Components/LiquidButton';
import  Vertical from './Components/Vertical';
import SliderComponent from './Components/SliderComponent';
import ThreeAnimated from './Components/ThreeAnimated';


const App = () => {
  const [currentLanguage, setCurrentLanguage] = useState('en');

  const handleLanguageChange = (language) => {
    setCurrentLanguage(language);
  };

  return (
    // <LanguageContext.Provider value={{ currentLanguage, setCurrentLanguage }}>
    <div className="App">
    {/* <Translate/> */}
      <Navbar />
      {/* <Translate> */}
      <Header/>
      <Top />
      {/* <TppWidget/> */}
      <Feature/>
      
      
      {/* <WaterButton/> */}
      <Phone/>
      <MovingTextComponent/>
      <ColumnsWithIcons />
      
      <TwoColumnLayout />
      <Faqs/>
      <Second/>
      {/* <Accordion /> */}
      <WhiteColumn />
      
      <Layout />
      <QAndA />
      <LatestNewsCards/>
      <Platform/>
      <Fast />
      <Bslide />
      <Steps />
      {/* <LineDot /> */}
      <Pricing />
      {/* <ShineCard/> */}
      {/* <CurlyLinesWithMovingDot/> */}
      <Footer />
      {/* <GlowingButton/> */}
      {/* <LiquidButton/> */}
      {/* <Vertical/> */}
      {/* <WaterButton/> */}
      {/* <AnimatedSVG/> */}
      {/* <BendingLine/> */}
      <RoundButton />
     <ScrollButton />
     {/* </Translate> */}
      
    </div>
    // </LanguageContext.Provider>
  );
}

export default App;



